.productItem {
  background: linear-gradient(180deg, rgb(230, 228, 220) 0%, rgb(242, 240, 234) 100%);
  border-radius: 10px;
  padding: 24px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productItem__image {
  width: 180px;
  margin: 0 auto 24px;
  transition: all 0.2 ease;
}

.productItem__image.featuredImage {
  width: 280px;
}

.productItem__image img {
  width: 180px;
  transition: all 0.2 ease;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.productItem__image.featuredImage img {
  width: 280px;
}

.productItem_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.newUsedTag {
  background: var(--theme-light);
  width: max-content;
  color: var(--theme-deep-orange);
  font-size: 11px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 2px 4px;
}

.productDate {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'NeueMontreal';
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;
  min-width: 120px;
}

.productPull {
  text-align: right;
}

.productItemFormat {
  opacity: 0.8s;
  font-size: 13px;
}

.featuredNote {
  opacity: 1.0;
  transition: opacity 0.2s ease;
}

.featuredNote:hover {
  cursor: pointer;
  opacity: 0.8;
}

.featuredNote.closed {
  color: #e53b3c;
}

.featuredNote.open {
  color: #565b5c
}

.productItem__controls {}

.previewControl {
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
  color: #e53b3c;
}

.productItem__title {
  font-family: 'ChronicleDisplayBold';
  font-size: 22px;
  line-height: 24px;
  margin: 6px 0;
  display: block;
}

.productContainer__featured .productItem__title {
  font-size: 26px;
  line-height: 30px;
}

.productItem__publisher {
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'NeueMontreal';
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.productItem__authors {
  font-size: 15px;
  margin-top: 4px;
  opacity: .68;
}

.productItem__info {
  display: flex;
  gap: 20px;
}

.productItem__left {
  flex: 3;
  text-align: left;
}


.productItem__right {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productControl__add {
  background: #e53b3c;
  display: inline-block;
  color: #fcfcfc;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 6px;
  border: 0;
  margin: 0 1px 0 0;
  line-height: 0;
  height: 24px;
  box-sizing: border-box;
  font-family: 'NeueMontreal';
  font-weight: 700;
  cursor: pointer;
}

.productControl__add:disabled {
  cursor: default;
  background: #e6e4dc;
  color: #565b5c;
}

.productControl__more {
  background: #e53b3c;
  color: #fcfcfc;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 0;
  border-radius: 0 6px 6px 0;
  border: 0;
  margin: 0 0 0 1px;
  height: 24px;
  box-sizing: border-box;
  font-family: 'NeueMontreal';
  font-weight: 700;
  display: inline-block;
}

.lowStock {
  font-size: 13px;
  color: #e53b3c;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.productItem__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.variantsText {
  font-size: 12px;
  color: #e53b3c;
}

.featuredBanner {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'NeueMontreal';
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;
  background: #e53b3c;
  color: #fcfcfc;
  padding: 4px 6px;
}

.productControl__more i {
  padding-top: 1px;
}

.productItemPrice {
  font-family: 'ChronicleDisplayBold';
  font-size: 18px;
  margin-bottom: 12px;
}

.productItemPrice__wholesale {
  color: #e53b3c;
  font-size: 13px;
  margin-top: 4px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.productItem__featuredPull {
  flex: 3;
  padding: 20px;
  border-radius: 10px;
  margin: 30px 0;
  position: relative;
  background: #f1f7f8;
  color: #394a51;
}

.productContainer__featured {
  grid-row: span 2;
}

.productContainer__featured .productItem {
  flex: 5;
  border-radius: 10px 0 0 10px;
}

.featuredQuotation {
  font-family: 'ChronicleTextItal';
  line-height: 1.4em;
  font-size: 18px;
}

.featuredQuotation em {
  font-family: 'ChronicleRegular';
  font-style: normal;
}

.featuredQuotation.larger {
  font-size: 23px;
}

.featuredQuotation p::before {
  content: '“'
}

.featuredQuotation p::after {
  content: '”'
}


.featuredAttribution {
  color: #063a4e;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 2px;
  text-align: right;
  text-transform: uppercase;
}

.arrowRight {
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  left: calc(50% - 5px);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #f1f7f8;
}

.productIcons {
  display: flex;
  gap: 6px;
  color: #e53b3c;
}

.previewModal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 30;
  display: flex;
}

.previewModal__content {
  width: calc(50% - 80px);
  height: calc(92% - 80px);
  margin: auto;
  background: #f2f0ea;
  padding: 40px;
  border-radius: 16px;
}

.previewModal__header {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
}

.closeButton {
  background: #e6e4dc;
  border-radius: 50%;
  border: 0;
  padding: 4px 8px;
  transition: all 0.4s ease;
  cursor: pointer;
}

.closeButton:hover {
  color: #fcfcfc;
  background: #e53b3c;
}

.onSaleBanner {
  color: #fcfcfc;
  background: #e53b3c;
  font-size: 15px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 3px 8px;
  text-align: center;
}

@media (max-width: 1200px) {
  .previewModal__content {
    width: calc(80% - 80px);
  }
}

@media (max-width: 800px) {
  .previewModal__content {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
  }
}