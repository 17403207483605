.catalogCover {
  max-width: 800px;
  width: 100%;
  margin: 24px auto 0 auto;
}

.catalogCard {
  padding-top: 24px;
  padding-bottom: 64px;
  text-align: center;
  margin-top: 24px;
}

.catalogCover img {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}