.pubMenu {
  position: sticky;
  width: 160px;
  top: 132px;
  padding: 40px 20px 40px 40px;
  height: calc(100vh - 132px);
  overflow-y: scroll;
}

.pubMenu__header {
  border-bottom: 1px solid #b7bec0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pubMenu__image {
  width: 80px;
}

.pubMenu__name {
  font-family: 'ChronicleDisplayBold';
  font-size: 22px;
  margin-top: 6px;
}


.pubMenu__image img {
  max-width: 100%;
  border-radius: 10px;
}

.newtag {
  background: #e33b3c;
  color: #fcfcfc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 10px;
}

.pubMenuLink {
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding-bottom: 12px;
  display: block;
}

.adminSubMenu {
  width: 100%;
  font-size: 14px;
}

.adminSubMenu .pubMenuLink {
  font-family: 'NeueMontreal';
}

@media(max-width: 800px) {

  .admin-page {
    padding-left: 0px;
  }

  .pubMenu__header {
    display: none;
  }

  .pubMenu {
    position: unset;
    width: 100%;
    padding: 0;
    background: none;
    border: 0;
    width: calc(100% - 40px);
    height: auto;
    overflow-x: scroll;
    padding: 20px;
  }

  .pubMenu__inner {
    display: flex;
    gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    width: max-content;
  }

  .pubMenu hr,
  .adminSubMenu hr {
    display: none;
  }

  .pubMenu .pseudoLabel {
    display: none;
  }

  .pubMenuLink {
    font-family: 'NeueMontreal';
    font-weight: 700;
    padding-bottom: 6px;
  }

  .adminSubMenu {
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}