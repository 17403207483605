.barGraph {
  height: 24px;
  display: flex;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.barGraph__data {
  height: 100%;
}

.breakdown {
  margin-top: 20px;
}

.breakdownLine {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
}

.breakdownKey {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.breakdownLabel {
  flex: 2;
}

.breakdownTotal {
  flex: 1;
}