.searchBar {
  position: relative;
  width: 40%;
  display: flex;
  z-index: 0;
}

.searchBarActive {
  z-index: 94;
}

.searchControl {
  position: relative;
}

.searchBar__input {
  padding: 8px 26px;
  background: none;
  border: 0;
  background: var(--theme-less-light);
  width: calc(100% - 24px);
  border-radius: 2rem;
  margin: auto;
  font-size: 16px;
  font-family: 'NeueMontreal';
}

.larger .searchBar__input {
  font-size: 22px;
  padding: 12px 24px;
}

.searchBar__button {
  position: absolute;
  background: none;
  cursor: pointer;
  color: var(--theme-off-black);
  border-radius: 4px;
  border: 0;
  padding: 0 10px;
  right: 012px;
  height: 32px;
  top: calc(50% - 16px);
}

.searchResults {
  position: absolute;
  width: 100%;
  top: 55px;
  z-index: 100;
}

.searchResultsMain {
  position: absolute;
  width: calc(100% - 32px);
  left: 16px;
  top: 34px;
  z-index: 100;
}

.searchResultIcon {
  display: inline-block;
  width: 24px;
  margin-right: 12px;
}

.larger .searchResults {
  top: 68px;
}

.searchResult {
  background: var(--theme-main-background);
  border-bottom: 1px solid var(--theme-less-light);
  padding: 10px;
  transition: all 0.2s ease;
  display: block;
}

.searchResult:hover {
  background: var(--theme-less-light);
}

.searchResultRow {
  display: flex;
  gap: 20px;
}

.searchImage {
  width: 36px;
}

.searchImage svg {
  width: 36px !important;
}

.searchImage-smaller {
  width: 26px;
}

.searchImage-smaller img,
.searchImage img {
  width: 100%;
}


.searchResult .searchResult__title {
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin: 2px 0;
}

.searchBar-mobile {
  display: none;
}

.quickHit {
  cursor: pointer;
}

.searchControl__input {
  padding: 6px 12px;
  width: calc(100% - 24px);
  background: #fcfcfc;
  outline: none;
  border: 1px solid #a7acae;
  font-family: 'NeueMontreal';
  border-radius: 4px;
  -webkit-appearance: none;
}

.resultsScreen {
  position: fixed;
  background: rgba(1,
      45,
      61, .2);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 80;
}

@media (max-width: 800px) {
  .searchBar {
    order: 3;
    width: 100%;
  }

}