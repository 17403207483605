.newHead {
  display: flex;
  margin-bottom: 24px;
}

.newHead a {
  font-family: 'NeueMontreal';
  font-weight: 700;
  color: #e53b3c;
  display: inline-flex;
  align-self: end;
}

.moreText {
  display: inline;
}

.moreMobile {
  display: none;
}

.homepageBookBlock {
  margin-bottom: 40px;
}