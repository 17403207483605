.blogPage {
  max-width: 1080px;
  margin: 0 auto;
}

.blogHeader {
  margin-bottom: 32px;
}

.blogTitle {
  font-family: 'ChronicleDisplayBold';
  font-size: 60px;
  margin: 12px 0 12px 0;
}

.blogMeta {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.blogLink {
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-transform: uppercase;
}

.singleImage {
  max-width: 680px;
  margin: 24px auto;
}

.singleImageContainer {
  display: flex;
  background: var(--theme-less-light);
}

.singleImageContainer img {
  max-width: 100%;
  max-height: 600px;
  margin: auto;
}

.blogText {
  font-family: 'ChronicleRegular';
  font-size: 20px;
  line-height: 1.8em;
  max-width: 680px;
  margin: 0 auto;
}

.blogText strong {
  font-family: 'ChronicleBold';
}

.blogText em {
  font-family: 'ChronicleTextItal';
  font-style: normal;
}

.blogText blockquote {
  padding-left: 20px;
  border-left: 4px solid var(--theme-less-light);
  font-family: 'ChronicleTextItal';
  font-size: .8em;
  line-height: 1.8em;
}

.blogText h2 em,
.blogText h3 em {
  font-family: 'NeueMontreal';
  font-style: italic;
}

.blogBookCard {
  max-width: 680px;
  margin: 0 auto;
}

.blogQuoteContainer {
  border-top: 1px solid #1f4e5f;
  border-bottom: 1px solid #1f4e5f;
  margin: 24px auto;
  padding: 24px 0;
}

.blogQuote {
  font-family: 'ChronicleDisplayBoldItal';
  font-size: 32px;
  line-height: 2em;
  color: #f13b3b;
}

.blogBookCardTitle {
  font-family: 'ChronicleDisplayBoldItal';
  font-size: 24px;
  margin: 4px 0 8px;
}

.blogQuoteSource {
  color: #f13b3b;
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-align: right;
}

.blogImage {
  margin: 32px 0 68px;
}

.blogImage img {
  max-width: 100%;
  border-radius: 10px;
}

.blogBookCard {
  /*border-top: 1px solid #1f4e5f;
  border-bottom: 1px solid #1f4e5f;*/
  margin: 24px auto;
  padding: 18px;
  background: var(--theme-less-light);
  border-radius: 10px;
  max-width: 680px;
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 24px;
}

.blogBookCardImage img {
  max-width: 100%;
}

.blogBookCardPrice {
  margin-top: 12px;
}

.blogGallery {
  margin: 24px 0;
}

.blogGallerySlide {
  background: #e6e4dc;
  position: relative;
}

.blogGallerySlide img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.blogGalleryCaption {
  margin-top: 8px;
  font-size: 14px;
}

.blogGallerySlide button.blogGalleryNav {
  opacity: 0.2;
  background: none;
  transition: all 0.2s linear;
}

.blogGallerySlide:hover button.blogGalleryNav {
  opacity: 0.8;
  background: #fcfcfc;
}

.blogGalleryNav {
  height: 100%;
  position: absolute;
  top: 0;
  width: 48px;
  padding: 0 12px;
  font-size: 48px;
  border: 0;
  outline: none;
}

.blogBooKCardMeta {
  font-weight: 700;
  font-size: 14px;
}

.blogGalleryNav-right {
  right: 0;
}

.blogBody {
  position: relative;
}

.blogBody .blogText:first-child p:first-child:first-letter {
  font-family: 'NeueMontreal';
  font-weight: 900;
  color: var(--theme-main-background);
  font-size: 92px;
  float: left;
  padding: 10px 8px 8px 0;
  text-shadow:
    -1px -1px 0 var(--theme-red),
    -1px 1px 0 var(--theme-red),
    1px -1px 0 var(--theme-red),
    1px 1px 0 var(--theme-red);
}

.blogText a {
  color: var(--theme-red);
}

.blogText a:hover {
  color: var(--hover-red);
}

@media (max-width: 900px) {
  .blogTitle {
    font-size: 36px;
  }

  .blogQuote p {
    margin-top: 0;
  }
}