.productControl_add-smaller {
  border-radius: 6px;
}

.productItem__title-small {
  font-size: 18px;
}

.productContainer_smaller {
  min-width: 16%;
}

.productContainer_smaller .productItem__left {
  display: block;
  flex: auto;
  text-align: center;
}

.productContainer_smaller .productItem__image {
  width: 84%;
}

.productContainer_smaller .productItem__image img {
  width: 100%;
}

.productContainer_smaller .productItem__image {
  width: 100%;
  text-align: center;
  padding: 0;
  display: flex;
  aspect-ratio: 5.5/7;
  transition: all 0.3s ease;
}

.productContainer_smaller .productItem {
  background: var(--theme-less-light);
  position: relative;
  padding: 24px;
  height: calc(100% - 48px);
}

.productContainer_smaller .productItem__image.alt {
  width: calc(100% + 48px);
  margin-left: -24px;
  text-align: center;
  padding: 24px 0;
  margin-top: -24px;
}

.productContainer_smaller .productItem__bottom {
  flex-direction: column;
}

.productContainer_smaller .productItem__controls {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.productSmallAddToCart {
  border-radius: 4px;
  padding: 4px 8px;
  border: 0;
  background: var(--theme-red);
  color: var(--theme-light);
  font-weight: 700;
  font-family: 'NeueMontreal';
  transition: all 0.2s ease;
  font-size: 15px;
  cursor: pointer;
}

.productSmallAddToCart i {
  margin-right: 4px;
}

.productSmallAddToCart:hover {
  background: var(--hover-red);
}

.productSmallWishlist {
  color: var(--theme-deep-blue);
  background: none;
  cursor: pointer;
  border-radius: 2rem;
  padding: 4px 8px;
  border: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: all 0.2s ease;
  opacity: 0.4;
}

.staffPickContainer {
  width: max-content;
  font-size: 14px;
  font-weight: 700;
  background: var(--theme-deep-blue);
  display: flex;
  color: var(--theme-light);
  gap: 10px;
  margin: -12px auto 0 auto;
  border-radius: 2rem;
  overflow: hidden;
  padding: 3px 12px 3px 3px;
}

.staffPickProduct {
  background: var(--theme-light) !important;
}

.staffPickImg {
  height: 30px;
  width: 30px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 2rem;
}

.staffPickName {
  margin: auto 0;
}

.productSmallWishlist-inline {
  background: var(--theme-light);
  border-radius: 4px;
  padding: 5px 8px;
  border: 0;
  margin-left: 8px;
  color: var(--theme-deep-blue);
  cursor: pointer;
  transition: all 0.2s ease;
}

.productSmallWishlist-inline:hover {
  color: var(--theme-red);
  background-color: var(--theme-less-light);
}

.productSmallWishlist:hover {
  background: var(--theme-less-light);
  opacity: 1;
}

.productContainer_smaller .productItemPrice {
  text-align: center;
  font-size: 16px;
}

.productContainer_smaller .productItem__right {
  display: none;
}

.productItem__image a {
  display: flex;
}

.productContainer_smaller .productItem__image img {
  max-width: 90%;
  margin: auto;
  max-height: 90%;
  height: auto;
  width: auto;
}

.productContainer_smaller .productItem__image.alt img {
  max-width: 80%;
  margin: auto;
  max-height: 90%;
}

.saleTag {
  color: #e53b3c;
  padding-left: 6px;
  font-size: 14px;
}

@media (max-width: 900px) {
  .productContainer_smaller .productItem__image {
    width: 180px;
  }
}