.wishlistButton {
  margin: auto 0;
  position: relative;
}

.wishlistButton a,
.wishlistButton button {
  background: var(--theme-less-light);
  border-radius: 2rem;
  cursor: pointer;
  padding: 4px 8px;
  display: inline-block;
  border: 0;
  outline: none;
  color: var(--theme-off-black);
  transition: all 0.2s ease;
  font-size: 14px;
}

.wishlistButton a:hover,
.wishlistButton button:hover {
  color: var(--theme-red)
}