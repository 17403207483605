footer {
  background: #e6e4dc;
  padding: 20px 40px;
}

.footerCopyright {
  margin-bottom: 20px;
  font-size: 13px;
  text-align: center;
}

.footerMenu {
  text-align: center;
  gap: 20px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  margin: 0 auto 20px;
}

.newsletterSignup {
  width: 100%;
  padding: 8px 0;
}

@media (max-width: 800px) {
  .footerMenu {
    flex-direction: column;
    gap: 12px;
  }
}