.megaMenu {
  position: fixed;
  top: 73px;
  left: 0;
  margin-left: -310px;
  width: 300px;
  background: #f2f0ea;
  height: calc(100% - 73px);
  z-index: 11;
  transition: all 0.2s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  overflow-y: scroll;
}

.megaMenuOpen {
  margin-left: 0;
}

.megaMenuItem {
  padding: 4px 40px 4px 40px;
}

.megaMenuSection:first-child {
  margin-top: 24px;
}

.megaMenuSection {
  padding-bottom: 24px;
}

.megaMenuSection h3 {
  margin: 12px 0;
  padding: 4px 12px;
  font-size: 18px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  border-radius: 4px;
}

.megaMenuSection:first-child h3 {
  background: #e53b3c;
  color: #fcfcfc;
}

.megaMenuSection:nth-child(2) h3 {
  background: #b9cdcc;
  color: #00374b;
}

.megaMenuSection:nth-child(3) h3 {
  background: #ab9566;
  color: #fcfcfc;
}

.megaMenuSection:nth-child(4) h3 {
  color: #fcfcfc;
  background: #00374b;
}



@media (max-width: 800px) {
  .megaMenuItem {
    padding: 4px 20px 4px 20px;
  }

  .megaMenu {
    top: 110px;
    height: calc(100% - 110px);
  }
}