.blogBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 40px;

}

.blogBlock_post {
  padding: 36px;
  border-radius: 10px;
  color: var(--theme-main-background);
}

.blogBlock_post:nth-child(1) {
  background: var(--theme-red);
}

.blogBlock_post h3 {
  margin: 0 0 8px 0;
}

.blogBlock_post h3 a {
  color: var(--theme-light);
}

.blogBlock_post:nth-child(2) {
  background: var(--theme-deep-orange);
}

.blogBlock_post:nth-child(3) {
  background: var(--theme-off-black);
}

.blogBlock_post:nth-child(4) {
  background: var(--theme-deep-blue);
}

.blogMeta {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 16px;
}

.blogSummary {
  font-family: 'ChronicleRegular';
  line-height: 1.4em;
  margin-top: 24px;
  margin-bottom: 16px;
}

.readMore {
  border: 2px solid var(--theme-light);
  color: var(--theme-light);
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 2rem;
}

@media (max-width: 800px) {
  .blogBlock {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .blogSummary {
    display: none;
  }

  .blogBlock_post {
    padding: 18px 12px;
  }
}