.productPageImage {
  width: 100%;
}

.productPageImage img {
  max-width: 100%;
}

.productPageHeader {
  margin-bottom: 40px;
}

.productPageHeader__publisher {
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.OnSaleBanner {
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  background: #fcfcfc;
  padding: 2px 6px;
  margin-left: 6px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 2px;
  color: #f13b3b;
}

.productPageHeader__stats {
  font-size: 14px;
}

.availabilityNotice {
  font-size: 13px;
  font-style: italic;
}

.productPageHeader__title {
  margin: 0;
  font-weight: normal;
  font-family: 'ChronicleDisplayBold';
  font-size: 42px;
}

.productPageHeader__subtitle {
  font-family: 'ChronicleDisplayBold';
  font-size: 20px;
  margin-bottom: 20px;
}

.productStats {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
}

.productStat {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.productStat__label {
  font-family: 'NeueMontreal';
  font-weight: 700;
  flex: 1;
}

.productStat__data {
  font-weight: normal;
  flex: 1;
}

.productAvailability {
  display: flex;
  column-gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.productAvailability__inventory {
  width: 100%;
  font-size: 14px;
  opacity: 0.8;
  margin-top: 10px;
}

.productAvailability__pubLink {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.productAvailability__inventory span {
  padding-right: 6px;
}

.productPContainer {
  font-family: 'ChronicleDisplayBold';
  font-size: 28px;
}

.product__list {
  font-size: 14px;
  margin-right: 8px;
  text-decoration: line-through;
}

.productPrice__discount {
  font-size: 14px;
  margin-left: 8px;
  color: var(--theme-red);
}

.buttonPrimary-red {
  background: var(--theme-red);
  border-color: var(--theme-red);
  transition: all 0.2s linear;
}

.buttonPrimary-red:hover {
  background: var(--hover-red);
  border-color: var(--hover-red);
}

.productDescription {
  margin: 40px 0;
  line-height: 1.6em;
  max-width: 800px;
}

.pageContent {
  line-height: 1.6em;
}

.pageContent h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.productDescription blockquote {
  font-family: 'ChronicleDisplayBoldItal';
  padding-left: 0;
  margin: 20px 0;
  line-height: 1.6em;
  font-size: 19px;
}

.productDescription cite {
  display: block;
  font-style: normal;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  opacity: 0.8;

}

.pageContent strong,
.productDescription strong,
.productDescription b {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.pageContent em,
.productDescription em,
.productDescription i {
  font-family: 'NeueMontreal';
}

.outOfStock {
  background: none;
  border-radius: 2rem;
  border: 2px solid #e6e4dc;
  background: #e6e4dc;
  color: #505657;
  padding: 8px 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.outOfStock-small {
  font-size: 13px;
  padding: 2px 8px;
}

.categoryList {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.categoryChosen {
  background: var(--theme-red);
  color: #fcfcfc;
  border-radius: 4px;
  padding: 4px 8px;
}

.categoryLink {
  background: var(--theme-less-light);
  color: var(--theme-deep-blue);
  border-radius: 2rem;
  padding: 3px 12px;
  font-weight: 700;
  font-size: 14px;
}

.productList__imageContainer {
  width: 36px;
}

.privateProduct {
  background: var(--theme-red);
  color: #fcfcfc;
  font-size: 13px;
  border-radius: 4px;
  padding: 2px 8px;
  margin-left: 8px;
}

.pubPrices {
  background: #fcfcfc;
  padding: 20px;
  border-radius: 8px;
}

.pubPrice__label {
  margin-bottom: 10px;
  font-size: 14px;
}

.previewButton {
  display: block;
  margin: 24px auto;
  color: var(--theme-red);
  font-weight: 400;
  font-family: 'NeueMontreal';
  font-weight: 700;
  background: none;
  padding: 6px 12px;
  border: 2px solid var(--theme-red);
  border-radius: 6px;
  cursor: pointer;
  transition: opacity 0.2s linear;
  opacity: 1.0;
}

.productAvailability__control {
  display: flex;
}

.previewButton:hover {
  opacity: 0.8;
}

.variants {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.variant {
  background: #fcfcfc;
  padding: 12px 18px;
  border-radius: 8px;
  font-size: 14px;
  gap: 8px;
  border: 1px solid #e6e4dc;
  margin-top: 1px;
}

.variantsHeader {
  margin: 32px 0 12px 0;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 13px;
}

.variant.current {
  background: #e5ecef;
  border: 2px solid #00374b;
  margin-top: 0;
}

.variantCover img {
  max-width: 40px;
  max-height: 70px;
  display: block;
}

.variantTitle {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.variantPrice {
  margin-top: 4px;
  font-size: 16px;
  font-family: 'ChronicleDisplayBold'
}

.usedSale {
  background: var(--theme-less-light);
  width: max-content;
  padding: 8px 18px;
  font-size: 13px;
  border-radius: 10px;
}

.usedHeader {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.usedMessage {
  margin-bottom: 8px;
}

.usedBuy {
  border: 2px solid var(--theme-deep-orange);
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 2rem;
  background: none;
  transition: all 0.2s ease;
  cursor: pointer;
  color: var(--theme-deep-orange);
}

.usedBuy:hover {
  border: 2px solid var(--hover-deep-orange);
  color: var(--hover-deep-orange);
}

@media (max-width: 800px) {
  .flexColMobile {
    flex-direction: column;
    gap: 10px;
  }

  .product__list {
    display: none;
  }

  .productStats {
    grid-template-columns: 1fr;
  }

  .productPageImage {
    margin-bottom: 20px;
  }

  .productPageHeader__stats {
    line-height: 1.4rem;
  }

  .productPageHeader__title {
    margin: 8px 0;
  }

  .productAvailability {
    gap: 20px;
    position: fixed;
    justify-content: space-between;
    margin: 0;
    padding: 10px 20px;
    background: var(--theme-main-background);
    border-top: 1px solid var(--theme-dark-accent);
    bottom: 0;
    left: 0;
    width: calc(100% - 40px);
  }
}