.emailSignup {
  background: var(--theme-deep-blue);
  padding: 36px;
  color: var(--theme-light);
  border-radius: 10px;
}

.emailHeadline {
  font-weight: 700;
  font-size: 26px;
  margin: 0 0 24px 0;
  color: var(--theme-deep-orange);
}

.emailDescription {
  font-family: 'ChronicleRegular';
  margin-top: 12px;
  font-size: 18px;
}

.emailError {
  margin: 12px 0;
  color: var(--theme-deep-orange);
  font-weight: 600;
}

.emailFinePrint {
  font-size: 12px;
  font-weight: 700px;
  color: var(--theme-light-blue);
}

.emailControls {
  display: flex;
  margin: 24px 0;
  gap: 20px;
}

.emailButton {
  background: var(--theme-red);
  font-size: 18px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  color: var(--theme-light);
  border: 0;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.emailConfirmed {
  color: var(--theme-light-blue);
  margin: 24px 0;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 800px) {
  .emailControls {
    flex-direction: column;
    gap: 8px;
  }
}