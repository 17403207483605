header {
  background: #f2f0ea;
  padding: 20px 40px;
}

.subhead {
  padding: 12px 40px;
  background: #e6e4dc;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.subheadContainer {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.subhead__list-left a {
  margin-right: 20px;
}

.subhead__list-right a {
  margin-left: 20px;
}

.cartCounterContainer {
  text-align: right;
  margin: auto 0;
}


.headerRight {
  display: flex;
  gap: 20px;
}

.headerLeft {
  display: flex;
  gap: 4px;
}

.cartCounter {
  border-radius: 2rem;
  padding-left: 8px;
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
}

.cartCounter i {
  margin-right: 8px;
  vertical-align: text-top;
}

.cartCounter:focus {
  border: 1px solid #000;
}

.cartCounterTag {
  background: #f13b3b;
  font-size: 12px;
  color: #fff;
  display: inline-flex;
  width: 14px;
  height: 14px;
  padding: 4px;
  margin-left: 4px;
  border-radius: 50%;
}

.betaBanner {
  background: #f13b3b;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 4px 40px;
  font-size: 13px;
  color: #fcfcfc;
  letter-spacing: .5px;
  text-align: center;
}

a.giftGuideLink {
  background: #f13b3b;
  color: #fcfcfc;
  font-size: 13px;
  color: #fcfcfc;
  padding: 2px 8px;
  margin-top: 1px;
  vertical-align: top;
  border-radius: 4px;
}

.publisherTag {
  background: #00374b;
  color: #fff;
  border-radius: 2rem;
  text-align: center;
  font-size: 14px;
  padding: 2px 0;
}

.userMenuOpenButton {
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  font-family: 'NeueMontreal'
}

.userMenuHeader {
  background: none;
  transition: all 0.2s ease;
  border-radius: 2rem;
  cursor: pointer;
  padding: 4px 14px;
}

.userMenuHeader.active,
.userMenuHeader:hover {
  background: var(--theme-less-light);
}

.userMenuHeader i {
  margin-right: 8px;
}

.userMenu {
  position: relative;
  font-size: 15px;
  cursor: pointer;
  background: #f2f0ea;
  z-index: 6;
  margin: auto;
}

.userMenu__expanded {
  position: absolute;
  right: 0;
  top: 36px;
  background: var(--theme-light);
  z-index: 10;
  border-radius: 10px;
  width: 280px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.userMenu__expanded ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.userMenu__expanded ul li {
  padding: 10px 20px;
  border-bottom: 1px solid var(--theme-dark-accent);
}

.userMenu__expanded ul li:last-child {
  border: 0;
}

.userMenu__expanded ul li:first-child {
  font-family: 'NeueMontreal';
  font-weight: 700;
  color: var(--theme-light);
  background: var(--theme-deep-blue);
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.headerLogo {
  display: flex;
}

.headerLogo__link {
  height: 28px;
  margin: auto 0;
}

.headerLogo__image {
  height: 28px;
}

.searchBar-mobile {
  display: none;
}

.stickyContainer {
  position: sticky;
  top: 0;
  z-index: 12;
  border-bottom: 1px solid var(--theme-dark-accent);
}

.megaMenuIcon {
  border: 0;
  background: none;
  color: #222;
  transition: all 0.2s linear;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 20px;
}

.megaMenuIcon:hover {
  color: #ef3b3b;
  background: #fcfcfc;
}

.mobileLabelHide {
  font-weight: 500;
}

@media (max-width: 1200px) {
  .subhead {
    font-size: 14px;
  }

  .headerLogo__image {
    height: 22px;
  }

  .mobileLabelHide {
    display: none;
  }
}

@media (max-width: 800px) {
  header {
    padding: 20px 20px;
  }

  .headerContainer {
    gap: 10px;
  }

  .headerRight {
    gap: 2px;
  }

  .headerLeft {
    gap: 1px;
  }

  .cartCounter i {
    margin-right: 2px;
    vertical-align: text-top;
  }

  .userMenu__expanded {
    top: 37px;
    right: -86px;
  }

  .homepageDescription p {
    margin-top: 0px;
  }

  .subhead {
    padding: 12px 20px;
    width: calc(100% - 40px);
    overflow-x: scroll;
  }

  .subheadContainer {
    width: max-content;
    margin-right: 20px;
    padding-right: 20px;
  }

  .subheadList {
    position: absolute;
    width: 100px;
    top: 100px;
    background: #e6e4dc;
  }

  .headerLogo__link {
    display: inline-flex;
  }

  .headerLogo__image {
    height: 18px;
    margin: auto 0;
  }

  .cartCounterContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }
}