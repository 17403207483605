.productListSmaller {
  display: grid;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  gap: 12px;
  position: relative;
  margin-bottom: 24px;
  overflow: visible;
}

.productListScroll-right {
  position: absolute;
  z-index: 10;
  right: -32px;
  top: calc(36% - 32px);
  width: 64px;
  width: 64px;
}

.productListScroll-left {
  position: absolute;
  z-index: 10;
  left: -32px;
  top: calc(36% - 32px);
  width: 64px;
  margin-right: 12px;
  width: 64px;
}

.productListScroll-right button,
.productListScroll-left button {
  height: 64px;
  display: block;
  border: 0;
  background: #fcfcfc;
  width: 64px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  position: relative;
}

.productListScroll-right button i,
.productListScroll-left button i {
  font-size: 16px;
  position: absolute;
  color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.productListScroll-right button:hover,
.productListScroll-left button:hover {
  background: #fcfcfc;
}

.productListScroll-right button:disabled,
.productListScroll-left button:disabled {
  color: #e6e4dc;
}

.productListScroll-right.behind,
.productListScroll-left.behind {
  z-index: -1;
  opacity: 0.5;
}

.sideScrollWrapper {
  display: flex;
  position: relative;
}

/* Exp */

.sideScrollContainer {
  position: relative;
}

.sideScrollContainer .sideScrollWrapper {
  overflow-x: scroll;
  margin-bottom: -10px;
  display: block;
}

.sideScrollWrapperWrapper {
  overflow: hidden;
}

.sideScrollContainer .productListSmaller {
  max-width: auto;
  width: max-content;
  margin-bottom: 12px;
}

.moreContainer {
  border-radius: 10px;
  padding: 40px;
  display: flex;
  width: 8vw;
}

.moreText {
  margin-bottom: 24px;
}

.moreBigLink {
  margin: auto;
  width: 100%;
  font-size: 24px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.sideScrollContainer .productContainer_smaller {
  width: 17vw;
}

@media (max-width: 1200px) {
  .sideScrollContainer .productContainer_smaller {
    width: 20vw;
  }
}


@media (max-width: 900px) {

  .productListScroll-right,
  .productListScroll-left {
    width: 48px;
    height: 48px;
  }

  .productListScroll-right button,
  .productListScroll-left button {
    width: 48px;
    height: 48px;
  }

  .productListScroll-left {
    left: -10px;
  }

  .productListScroll-right {
    right: -10px;
  }

  .sideScrollContainer .productContainer_smaller {
    width: 29vw;
  }

  .moreContainer {
    width: 20vw;
  }
}

@media (max-width: 800px) {
  .sideScrollContainer .productContainer_smaller {
    width: 36vw;
  }
}

@media (max-width: 700px) {

  .productListScroll-left,
  .productListScroll-right {
    display: none;
  }

  .moreText {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    width: 56vw;
    margin: 0;
    padding: 0;
    text-align: center
  }

  .sideScrollContainer .productContainer_smaller {
    width: 60vw;
  }

  .moreContainer {
    width: 8vw;
    position: relative;
    padding: 12px;
  }
}