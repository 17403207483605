.actionModal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .24);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
}

.actionConfirm {
  margin: auto;
  background-color: var(--theme-main-background);
  padding: 40px 64px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
}

.actionConfirmLabel {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}

.actionConfirmText {
  margin: 12px 0 24px;
}

.actionConfirmOptions {
  display: flex;
  gap: 24px;
  justify-content: center;
  ;
}