.filteredGrid {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 48px;
}

.sidebarFilter {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e4dc;
}

.sidebarFilter .inputControl {
  margin-bottom: 0;
}

.sidebarFilter .rangeSlider {
  margin-bottom: 24px;
}

.catList {
  margin-top: 16px;
}

.catListItem {
  margin-bottom: 6px;
  display: flex;
}

.catSelector {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  background: #e6e4dc;
  margin-right: 12px;
}

.catSelected {
  background: #00374b;
}

.filterMobile {
  display: none;
}

.blogPreviewMessage {
  background: var(--theme-red);
  color: var(--theme-light);
  padding: 8px 12px;
  margin-bottom: 24px;
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .filteredGrid {
    grid-template-columns: 1fr;
  }

  .filterPanel {
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px 64px;
    background: #fcfcfc;
    z-index: 40;
    overflow-y: scroll;
    height: calc(100% - 80px);
    width: calc(100% - 128px);
    display: none;
  }

  .filterPanel.filterPanelOpen {
    display: block;
  }

  .filterMobile {
    display: block;
  }

  .filterSticky {
    position: sticky;
    top: 0;
  }

  .filterSticky button {
    display: block;
    width: 100%;
    background: #fcfcfc;
  }
}