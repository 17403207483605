.blogGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;
}

.blogPost {
  padding: 24px 48px;
  border-radius: 10px;
}

.blogPost h2 {
  margin: 0;
  font-size: 26px;
  line-height: 1.2em;
}

.blogMeta {
  font-weight: 700;
  margin-top: 8px;
  font-size: 15px;
}

.blogSummary {
  font-family: 'ChronicleRegular';
  line-height: 1.4em;
  margin-top: 24px;
}

.blogTags {
  margin-bottom: 12px;
  display: flex;
  gap: 8px;
}

.blogTag {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  padding: 2px 10px;
  border-radius: 2rem;
}

.blogPost:nth-child(1),
.blogPost:nth-child(7) {
  grid-column: span 2;
}

.blogPost:nth-child(1) h2,
.blogPost:nth-child(7) h2 {
  font-size: 36px;
}

.blogPost:nth-child(5n+1) {
  background: var(--theme-red);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+1) .blogTag {
  background: var(--theme-deep-blue);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+1) a,
.blogPost:nth-child(5n+2) a {
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+2) {
  background: var(--theme-deep-blue);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+2) .blogTag {
  background: var(--theme-deep-orange);
  color: var(--theme-main-background);
}



.blogPost:nth-child(5n+3) {
  background: var(--theme-light-blue);
  color: var(--theme-deep-blue);
}

.blogPost:nth-child(5n+3) .blogTag {
  background: var(--theme-red);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n + 4) {
  background: var(--theme-deep-orange);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+4) .blogTag {
  background: var(--theme-deep-blue);
  color: var(--theme-main-background);
}

.blogPost:nth-child(5n+5) {
  background: var(--theme-light);
  color: var(--theme-deep-blue);
}

.blogPost:nth-child(5n+5) .blogTag {
  background: var(--theme-light-blue);
  color: var(--theme-deep-blue);
}

.blogNews {
  background: var(--theme-light);
  padding: 12px 24px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.blogNews path {
  fill: var(--theme-light-blue);
}

.blogNews svg {
  position: absolute;
  width: 200%;
  transform: rotate(45deg);
  transform-origin: 90% 84%;
}

.blogNews h3 {
  color: var(--theme-red);
}

.moreNewsLink {
  margin-top: 24px;
  display: inline-block;
  font-size: 14px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.blogHeadline {
  color: var(--theme-deep-blue);
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-bottom: 12px;
  padding-left: 4px;
}

.newsList {
  margin: 0;
  padding: 0 0 0 13px;
}

.blogHeadline::marker {
  content: '⁂';
  margin-right: px;
  color: var(--theme-red);
}

.blogGridAlt {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;
}

.blogPostAlt:nth-child(6n + 1) {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.blogPostAlt {}

.blogPostAltImage {
  overflow: hidden;
  border-radius: 10px;
}

.blogPostAltImage img {
  max-width: 100%;
}

@media (max-width:1000px) {
  .blogGrid {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}