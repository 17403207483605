.bestsellerItem {
  display: grid;
  margin-bottom: 12px;
  grid-template-columns: 48px 220px 3fr 2fr 1fr;
}

.bestsellerNo {
  background: #e33b3c;
  color: #fcfcfc;
  display: inline-block;
  display: inline-flex;
  border-radius: 8px 0 0 8px;
}

.bestsellerDigit {
  display: inline-block;
  margin: auto;
  font-family: 'ChronicleDisplayBold';
  font-size: 32px;
}

.bestsellerCover {
  overflow: hidden;
  padding: 21px;
}

.bestsellerCover img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 25px 20px -20px rgba(0, 0, 0, .45);
  margin: 0 auto;
  text-align: center;
}

.bestsellerCard {
  max-width: 1200px;
  margin: 0 auto 48px;
}

.bestsellerCart {
  background: #e33b3c;
  color: #fcfcfc;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 4px 8px;
  font-size: 14px;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.bestsellerCart:hover {
  background: #cd193d;
}


.bestsellerTitle {
  font-family: 'ChronicleDisplayBold';
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 6px;
}

.bestsellerAuthor {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.bestsellerData {
  display: flex;
  padding-left: 24px;
}

.bestsellerDataContainer {
  margin: auto 0;
}

@media (max-width: 1200px) {
  .bestsellerItem {
    grid-template-columns: 48px 180px 3fr 2fr 1fr;
  }
}

@media (max-width: 800px) {
  .bestsellerItem {
    display: flex;
    flex-direction: column;
  }

  .bestsellerNo {
    border-radius: 8px 8px 0 0;
  }

  .bestsellerDigit {
    font-size: 28px;
  }

  .bestsellerCover img {
    display: block;
  }

  .bestsellerData {
    text-align: center;
    padding-bottom: 12px;
    display: block;
  }
}