.ghostTextContainer {
  --percentage: 200%;
  margin: 0 auto;
}

.ghostMargin {
  margin-top: 4px;
}

@keyframes shimmer {
  from {
    background-position: var(--percentage) 0;
  }

  to {
    background-position: calc(var(--percentage) * -1) 0;
  }
}

.shimmer {
  animation: shimmer 2s linear infinite;
  background: var(--theme-less-light) linear-gradient(to right, transparent 0%, rgba(206, 204, 198, 0.2) 25%, transparent 50%);
  background-size: var(--percentage) 100%;
  width: var(--width);
}

.ghostImage {
  width: 180px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.productContainer_smaller .ghostTextContainer>div {
  margin-left: auto;
  margin-right: auto;
}