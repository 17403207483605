.librarySub {
  padding: 10px 0;
  border-bottom: 1px solid #a7acae;
}

.librarySubTitle {
  display: flex;
}

.librarySubTitle__pub {
  font-family: 'NeueMontreal';
  font-weight: 700;
  display: inline-flex;
}

.librarySubTitle__pubName {
  margin: auto 0;
}

.librarySubTitle__image {
  height: 24px;
  border-radius: 4px;
  margin-right: 10px;
}

.librarySubInfo {
  font-size: 14px;
  opacity: 0.8;
}

.subHeader {
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-top: 40px;
}

.subHeaderer {
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-bottom: 6px;
}

.pubOption {
  padding-left: 20px;
}

.publisherNameGrid {
  column-count: 5;
  column-gap: 24px;
  margin-bottom: 40px;
}

.publisherLogoSmall {
  height: 19.8px;
  width: 19.8px;
  background-color: var(--theme-off-black);
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
}

.publisherName {
  display: block;
  margin-bottom: 2px;
}

.publisherGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.publisherGridItem__image {
  width: 200px;
  border-radius: 8px;
  overflow: hidden;
}

.publisherGrid-small .publisherGridItem__image {
  flex: 1;
  height: auto;
  display: block;
  line-height: 0;
  border-radius: 8px;
  overflow: hidden;
}

.publisherGridItem__image img {
  width: 100%;
  border-radius: 10px;
}

.publisherGridItem {
  background: linear-gradient(180deg, rgb(230, 228, 220) 0%, rgb(242, 240, 234) 100%);
  padding: 20px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 40px;
}

.publisherGridItem-small {
  display: flex;
  gap: 0px;
  background: none;
  border-radius: 0;
}

.publisherItem__description {
  margin-bottom: 16px;
}

.publisherGrid-small .publisherGridItem__info {
  flex: 3;
  padding: 10px;
  margin: auto 0;
}

.publisherItem__title {
  font-family: 'ChronicleDisplayBold';
  font-size: 26px;
}

.publisherGridItem-small .publisherItem__title {
  font-size: 16px;
}

.publisherItem__location {
  font-size: 13px;
  margin-bottom: 20px;
}

.publisherGridItem-small .publisherItem__location {
  margin-bottom: 0px;
}

@media (max-width: 1200px) {

  .publisherGrid {
    grid-template-columns: 1fr;
  }

  .publisherNameGrid {
    column-count: 3;
  }
}

@media (max-width: 860px) {

  .publisherGrid-small,
  .publisherGrid {
    grid-template-columns: 1fr;
  }

  .publisherNameGrid {
    column-count: 2;
  }
}

@media (max-width: 600px) {

  .publisherGrid {
    grid-template-columns: 1fr;
  }

  .publisherNameGrid {
    column-count: 1;
  }

  .publisherGrid-small {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 20px;
  }

  .publisherGridItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}