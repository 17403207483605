.publisherHeader {
  display: flex;
  gap: 20px;
  margin-bottom: 0;
}

.publisherLocation {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.publisherLinks {
  font-size: 14px;
}

.publisherHeading {
  font-family: 'ChronicleDisplayBold';
  font-size: 42px;
  margin: 0 0 8px 0;
}

.publisherLogo {
  max-width: 200px;
}

.publisherLogo__image {
  max-width: 100px;
  border-radius: 8px;
}

.listOfLists {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.pubListItem {
  background: linear-gradient(180deg, rgba(205, 209, 210, 1) 0%, rgba(230, 230, 230, 1) 100%);
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.pubListItem__image {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.pubListItem__image img {
  max-width: 80px;
}

.pubListItem__info {
  padding: 12px;
  margin: auto;
}

.pubListItem__name {
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-bottom: 4px;
}

.pubListItem__meta {
  opacity: 0.68;
  font-size: 13px;
}