.headline {
  background: var(--theme-less-light);
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.headline h2 {
  margin: 6px 0 12px;
}

.headlineSummary {
  font-family: 'ChronicleRegular';
  line-height: 1.4em;
  margin-bottom: 24px;
}

.headlineMeta {
  font-weight: 700;
  font-size: 15px;
}