.displayMessage {
  position: fixed;
  z-index: 1000;
  padding: 20px 20px;
  font-size: 16px;
  top: 80px;
  gap: 10px;
  border-radius: 10px;
  left: calc(50% - 200px);
  background: #fcfcfc;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 400px;
  opacity: 1.0;
  transition: all 0.4s ease;
  display: flex;
  justify-content: space-between;
}

.messageClear {
  width: 8px;
  height: 8px;
}

.messageButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.messageClear svg {
  vertical-align: super;
}


.messageClear svg .cls-1 {
  fill: #46535d
}

.messageClear svg:hover .cls-1 {
  fill: #ce482d;
}

.messageLabel {
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 6px;
  opacity: 0.64;
  text-transform: uppercase;
}

.messageImage {
  width: 36px;
}

.messageInfo {
  flex: 4;
}

.messageImage img {
  width: 100%;
}

.displayMessage.success {
  border-left: 8px solid #82dda7;
}

.displayMessage.warn,
.displayMessage.error {
  border-left: 8px solid #ce482d;
}

.displayMessage.info {
  border-left: 8px solid #151b23;
}

.displayMessage.invisible {
  opacity: 0;
}

@media (max-width: 800px) {
  .displayMessage {
    left: 20px;
    width: calc(100% - 80px);
  }
}