.headerCart {
  position: fixed;
  top: 0;
  right: calc(-48% - 120px);
  background: #f2f0ea;
  color: #000;
  padding: 20px 40px 40px;
  width: 48%;
  transition: all 0.4s ease;
  height: calc(100% - 60px);
  overflow-y: scroll;
  z-index: 12;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.headerCart__open {
  right: 0px;
}

.cartScreen {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 0;
  z-index: 11;
  background: var(--theme-off-black);
  opacity: 0.2;
  transition: all 0.4s ease;
}

.usedTag {
  background: var(--theme-red);
  width: max-content;
  display: inline-block;
  margin-left: 2px;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--theme-light);
  font-weight: 700;
  padding: 1px 5px;
  border-radius: 2px;
}

.headerItemOptions {
  margin-top: 3px;
  color: var(--theme-dark-accent);
}

.headerItemOptions button {
  font-family: 'NeueMontreal';
  color: var(--theme-deep-blue);
  padding: 0;
  cursor: pointer;
  margin: 0;
  background: none;
  transition: all 0.2s ease;
  border: none;
}

.headerItemOptions button:hover {
  color: var(--theme-red);
}

.shippingAlert {
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 13px;
  color: #f13b3b;
  margin-bottom: 12px;
}

.cartScreen-open {
  width: 52%;
}

.headerCartList {
  margin-bottom: 20px;
}

.subtotal {
  margin-bottom: 20px;
}

.subtotal__label {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.headerCartLabels {
  display: grid;
  grid-template-columns: 40px 2fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 4px 0;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;
}

.headerCartItem {
  display: grid;
  grid-template-columns: 40px 2fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 6px 0;
  font-size: 14px;
  margin-bottom: 6px;
  border-bottom: 1px solid #b7bec0;
}

.headerCartItem__image {
  width: 40px;
}

.headerCartItem__image img {
  width: 40px;
}


.headerCartItem__field {
  display: inline-flex;
}

.headerCartItem__content {
  margin: auto 0;
}

.headerCartItem__small {
  font-size: 14px;
  font-family: 'NeueMontreal';
  font-weight: 400;
}

.headerCartItem__content input {
  max-width: 60px;
}

.headerCartItem__title {
  font-family: 'NeueMontreal';
  font-weight: 700;
  flex: 2;
}

.headerCartItem__discount {
  color: var(--theme-red);
  font-size: 12px;
  margin-left: 6px
}

.cartHeader {
  margin-bottom: 20px;
}

.cartHeader__title {
  margin-top: 0;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 30px;
  color: #1c1c1c;
}

.cartHideButton {
  display: inline-block;
  background: 0;
  border: 2px solid #00374b;
  border-radius: 2rem;
  padding: 4px 12px;
  font-weight: 500;
  font-family: 'NeueMontreal';
  cursor: pointer;
}

.cartRemoveButton {
  border-radius: 2rem;
  border: 0;
  background: var(--theme-dark-accent);
  cursor: pointer;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 2px 4px;
  transition: all 0.2s ease;
}

.cartRemoveButton:hover {
  background: var(--hover-red);
  color: var(--theme-light);
}

.cartButtons {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.cartButtonPrimary {
  background: #00374b;
  color: #f2f0ea;
  border-radius: 2rem;
  border: 0;
  padding: 4px 12px
}

.cartButtonSecondary {
  background: none;
  border-radius: 2rem;
  border: 2px solid #00374b;
  color: #00374b;
  padding: 4px 12px
}

.cartHelpText {
  margin: -10px 0 20px 0;
  font-size: 13px;
}

.cartHelpText a {
  cursor: pointer;
}

.cartBreakdown {
  margin-top: 30px;
}

.cartBreakdown__line {
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
}

.cartBreakdown__total {
  border-top: 1px solid #b7bec0;
  padding-top: 6px;
  display: inline-block;
}

.cartBreakdown__extra {
  font-size: 13px;
  opacity: 0.64;
  margin-top: 8px;
}

.checkoutError {
  background: #ecd6d6;
  color: #df1b41;
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.cartButton,
.loadMore {
  min-width: 500px;
  max-width: 750px;
  width: 100%;
  background: #df1b41;
  color: #fcfcfc;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 20px 0;
  font-size: 18px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

.loadMore {
  margin: 24px auto;
}

.cartButton:hover,
.loadMore:hover {
  background: #cd193d;
}

.cartButton:disabled,
.loadMore:disabled {
  background: #e18296;
  cursor: default;
}

.payPalButtons {
  border-top: 1px solid #a7acae;
  padding-top: 24px;
}

.fa-check {
  color: #1bdf42;
}

.fa-x {
  color: #df1b41;
}

.cartCountControl {
  display: flex;
  background: var(--theme-less-light);
  width: max-content;
  border-radius: 2rem;
}

.cartCountControl button {
  background: none;
  border-top: 0;
  border-bottom: 0;
  outline: none;
  padding: 4px 10px;
  cursor: pointer;
  transition: all 0.2 ease;
}

.cartCountControl button:hover {
  background: var(--theme-dark-accent);
}

.inlineCartCount {
  padding: 4px 12px 0 12px
}

.cartCountControl button:first-child {
  border-radius: 2rem 0 0 2rem;
  border-left: 0;
  border-right: 1px solid var(--theme-dark-accent);
}

.cartCountControl button:last-child {
  border-radius: 0 2rem 2rem 0;
  border-right: 0;
  border-left: 1px solid var(--theme-dark-accent);
}

.cartCountControl button:disabled {
  cursor: unset;
  background: none;
}

.shippingRates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.shippingRate {
  border-radius: 8px;
  margin-bottom: 6px;
  background: var(--theme-light);
  padding: 6px 18px;
  font-size: 14px;
  display: flex;
  gap: 4px;
  cursor: pointer;
  border: 2px solid var(--theme-less-light);
}

.shippingRate:hover {
  background: var(--theme-less-light);
}

.shippingRate.selectedRate {
  border-color: var(--theme-red);
  background: #f3e7e7;
}

.shippingEstimate {
  color: #85837e;
  margin-top: 2px;
}

.shippingPrice {
  font-size: 16px;
  margin-bottom: 2px;
}

@media (max-width: 1200px) {

  .cartScreen-open {
    width: 0;
  }

  .cartHeader {
    margin-bottom: 0;
  }


  .headerCart {
    width: calc(100% - 40px);
    right: calc(-100% - 80px);
    padding: 20px;
  }

  .headerCart__open {
    right: 0px;
  }

  .headerCartLabels {
    display: none;
  }

  .headerCartItem {
    grid-template-columns: 40px 1fr 1fr 1fr;
    padding-bottom: 12px;
  }

  .headerCartItem__image {
    grid-row: span 2;
  }

  .headerCartItem__title {
    grid-column: span 3;
  }
}

@media (max-width: 500px) {

  .cartButton {
    min-width: 300px;
    max-width: 500px;
  }
}