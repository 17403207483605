.bookBody {
  fill: #f2f0ea;
}

.bookBackground {
  fill: var(--theme-dark-accent);
}

.bookBackgroundDark {
  fill: var(--theme-deep-blue);
}