.counterContainer {
  margin: auto 0;
}

.counter {
  background: var(--theme-less-light);
  border-radius: 2rem;
  cursor: pointer;
}

.counterIcon {
  padding: 4px 14px;
  display: inline-block;
}

.counterTag {
  background: var(--theme-dark-accent);
  padding: 4px 12px;
  color: var(--theme-light);
  border-radius: 2rem;
}

.counterTagActive {
  background: var(--theme-red);
}

.counterTagNo {
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 800px) {
  .counterIcon {
    padding: 2px 12px;
  }

  .counterTag {
    display: none;
  }
}