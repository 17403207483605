.homepageWelcome {
  background: linear-gradient(120deg, rgba(241, 59, 59, 1) 0%, rgba(3, 56, 76, 1) 67%);
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  color: #fcfcfc;
  /*background: url('./homepage.png');*/
  background-size: cover;
}

.homepageWelcome__inner {
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #fcfcfc;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  gap: 20px;
}

.newPubList {
  background: linear-gradient(180deg, rgba(205, 209, 210, 1) 0%, rgba(230, 230, 230, 1) 100%);
  border-radius: 10px;
  padding: 24px 24px 12px 24px;
  grid-row: span 2;
  order: 3;
  position: relative;
}

.newPubsContainer {
  max-height: 1000px;
  overflow-y: scroll;
  scrollbar-color: #e03b3c #e6e4dc;
  scrollbar-width: thin;
}

.newPubList::after {
  content: ' ';
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(230, 230, 230, 0) 0%, rgba(230, 230, 230, 1) 100%);
  z-index: 8;
}

h2.newPubTitle {
  font-family: 'ChronicleDisplayBold';
  text-align: center;
  margin: 0 auto 24px auto;
}

.newPubsList {
  height: max-content;
  padding-bottom: 48px;
}

.homepageTitle {
  font-family: 'ChronicleDisplayBold';
  font-size: 36px;
  margin: auto 0;
}

a.homepub_name {
  color: #000;
}

.homepageTitlePress {
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}


.homepageDescription {
  max-width: 1100px;
  font-family: 'ChronicleRegular';
  font-size: 20px;
  line-height: 1.8rem;
  margin: auto;
}

.homepageDescription p {
  margin: 0;
}

.homepageShortcutLabel {
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.homepageOptions {
  display: flex;
  gap: 10px;
}

.homepageOption {
  color: #f2f0ea;
  font-family: 'NeueMontreal';
  font-weight: 700;
  font-size: 14px;
  background: #f13b3b;
  padding: 4px 12px;
  border-radius: 4px;
}

h2.productSubhead,
.displayHeader {
  font-weight: normal;
  font-family: 'ChronicleDisplayBold';
  font-size: 28px;
}

.homepageGrid {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.homepageFeatured {
  grid-column: span 4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 40px;
}

.newHead h2 {
  margin-top: 0;
  font-size: 30px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-bottom: 0;
}

.homepagePresses {
  background: #e6e4dc;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.homepagePresses h2 {
  margin-top: 0;
}

.homepagePresses__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.homepagePresses__grid h3 {
  color: #fcfcfc;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.pressGrid__container {
  width: 100%;
}

.homepub {
  margin-bottom: 8px;
  display: flex;
  gap: 12px;
}

.homepub_image {
  width: 28px;
  height: 28px;
  overflow: hidden;
  border-radius: 50%;
}

.homepub_info {
  margin: auto 0;
}

.homepub_image img {
  width: 28px;
  height: 28px;
}

.homepub_name {
  font-family: 'ChronicleDisplayBold';
  font-size: 16px
}

.homepub_location {
  font-size: 13px;
  margin-top: 4px;
  opacity: .68;
}

.lowerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
}

.bestseller {
  display: flex;
  gap: 12px;
  margin-bottom: 10px
}


.bestseller__no {
  background: #e33b3c;
  color: #fcfcfc;
  width: 30px;
  flex: 1;
}

.bestseller:nth-child(even) .bestseller__no {
  background: #e6e4dc;
}

.bestseller__cover {
  width: 60px;
}

.bestseller__info {
  flex: 6;
  margin: 0 auto;
}

.bestseller__pub {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'NeueMontreal';
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.bestseller__title {
  font-family: 'ChronicleDisplayBold';
  font-size: 18px;
}

.bestseller__cover img {
  width: 60px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.homepub_info {
  text-align: center;
  margin: 0 auto;
}

.categoryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 24px;
  text-align: center;
}

.catGrid_image {
  background: rgb(205, 209, 210);
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
}

.catGrid_image img {
  max-width: 80%;
  margin: auto;
}

.catHeader {
  margin-top: 0;
  font-size: 30px;
  font-family: ChronicleDisplayBold;
  margin-bottom: 24px;

  text-align: center;
}

.catGrid_name {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.newsletterBlock {
  background: #fcfcfc;
  padding: 24px;
  border-radius: 10px;
  color: #03384c;
  text-align: center;
  margin-bottom: 40px;
}

.newsletterSignupNew input {
  background: none;
  border-bottom: 1px solid #b9cdcc;
  color: var(--theme-light);
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 4px 0;
  width: 20vw;
  font-family: 'NeueMontreal';
  font-size: 18px;
}

.newsletterSignupNew input::placeholder {
  color: rgba(250, 250, 250, 0.6)
}

.newsletterBlock_header {
  font-size: 20px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.newsletterBlock_controls {
  display: flex;
  margin: 24px 0;
  gap: 20px;
}

.newsletterButton {
  background: #e53b3c;
  font-size: 18px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  color: #f2f0ea;
  border: 0;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.newsletterConfirm {
  margin: 12px 0;
}

.newsletterFinePrint {
  font-size: 10px;
  opacity: 0.6;
  font-weight: 600;
}

.inputControl.newsletterSignupNew {
  margin-bottom: 0;
}

.hero {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 36px;
}

.homepageTitle {
  margin-bottom: 24px;
}

.heroBlock {
  border-radius: 10px;
  transition: all 0.2s ease;
}

.heroBlock:first-child {
  background: var(--theme-red);
  grid-column: span 1;
  grid-row: span 2;
  padding: 40px;
  font-size: 18px;
  font-family: 'NeueMontreal';
  font-weight: normal;
  color: var(--theme-light);
}

.heroBlock:nth-child(2) {
  background: var(--theme-light-blue);
  padding: 20px;
  font-size: 18px;
  font-family: 'NeueMontreal';
  color: var(--theme-deep-blue);
  grid-column: span 3;
}

.heroLabel {
  margin: auto 0;
}

.heroLabel.newsletter {
  font-size: 25px;
  color: var(--theme-deep-orange);
  font-weight: 700;
}

.heroBlock:nth-child(3) {
  background: var(--theme-deep-orange);
  background: url('https://files.asterismbooks.com/ffe295f2bd48647c7f354d7cb024bc24/1739299165-300.png');
  font-size: 18px;
  padding: 20px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  color: #fcfcfc;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.visitTop {
  display: inline-block;
  background: var(--theme-light);
  border-radius: 2rem;
  color: var(--theme-deep-blue);
  width: max-content;
  padding: 4px 12px;
}

.visitBottom {
  text-align: right;
}

.visitBottom a {
  color: var(--theme-main-background);
  border-radius: 2rem;
  padding: 4px 12px;
  background: var(--theme-red);
}

.heroBlock:nth-child(4) {
  background: var(--theme-deep-blue);
  padding: 20px;
  font-size: 18px;
  font-family: 'NeueMontreal';
  color: #fcfcfc;
  grid-column: span 2;
}

.heroNewsletterText {
  font-family: 'ChronicleRegular';
  margin-top: 12px;
}

.blogPostLink {
  font-family: 'NeueMontreal';
}

.mobileGreeting {
  color: var(--theme-light);
  border-radius: 8px;
  padding: 4px 12px;
  background: var(--theme-red);
}


@media (min-width: 1800px) {
  .lowerGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .newPubList {
    order: 4;
  }
}

@media (max-width: 1500px) {
  .lowerGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .newPubList {
    order: 2;
  }
}

@media (max-width: 1120px) {
  .lowerGrid {
    grid-template-columns: 1fr 1fr;
  }

  .newPubList {
    order: 2;
  }

  .homepagePresses__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 800px) {
  .homepageTitle {
    font-size: 18px;
  }

  .hero {
    display: none;
    flex-direction: column;
    gap: 12px;
  }

  .newHead h2 {
    font-size: 22px;
  }

  .lowerGrid {
    display: flex;
    flex-direction: column;
  }

  .categoryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    text-align: center;
  }

  .newPubList {
    order: 4;
  }

  .newPubsContainer {
    max-height: 300px;
  }

  .homepagePresses__grid {
    grid-template-columns: 1fr 1fr;
  }

  .newsletterSignupNew input {
    width: 100%;
  }
}


@media (max-width: 600px) {
  .homepageWelcome__inner {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .heroBlock:nth-child(4),
  .heroBlock:nth-child(3) {
    display: none;
  }

  h1.page-header {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .publisherHeader,
  .pageHeadContainer {
    margin-bottom: 20px;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .publisherLogo {
    margin: 0 auto;
  }

  .mobileScrolling {
    width: calc(100% + 20px);
    overflow-x: scroll;
    margin-bottom: 20px;
  }

  .mobileScroll {
    display: flex;
    width: max-content;
    padding-right: 20px;
  }

  .mobileScroll .productItem {
    width: 170px;
  }

  .mobileScroll .featuredProduct {
    width: 600px;
  }

  .mobileScroll .productItem .productItemPrice {
    flex-direction: column;
  }

  .publisherGridItem-small {
    width: 300px;
  }

  .categoryList {
    margin-bottom: 10px;
  }
}