.supportPage {
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 48px;
}

.orderStatusMessage {
  background: #fcfcfc;
  padding: 6px 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-top: 12px;
  border-radius: 4px;
}