.paginator {
  display: flex;
}

.paginator button {
  background: #00374b;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  color: #fff;
  border: 0;
  outline: none;
  display: inline-flex;
  font-size: 20px;
  margin: 0 2px;
}

.paginator button:disabled {
  opacity: 0.4;
}

.paginator__label {
  margin: auto 6px;
}

.paginator button span {
  margin: auto;
  line-height: 20px;
}

.loadingMessage {
  margin: 20px auto;
  display: inline-flex;
  gap: 20px;
  text-align: center;
}

.loadingMessage__text {
  margin: auto 0;
}

@media (max-width: 800px) {
  .paginator {
    display: inline-flex;
    margin: 0 auto;
    text-align: center;
  }
}